/*
 * //////////////////////////////////////////////
 * GENERAL UTIL FUNCTIONS CLIENT AND SERVER SIDE
 * //////////////////////////////////////////////
 */
import { storeLinkClassName } from "@/services/ceSettings/ceSettingsService";
import { cmsTranslate } from "@/services/cmsTranslation/cmsTranslationService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { reduxStore } from "@/store/store";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import ResponseResult from "@/types/classes/ResponseResult";
import { StrapiUploadFile, StrapiUploadFileFormat } from "@/types/strapi";
import { marked } from "marked";
import { NextRouter } from "next/router";
import React, { ReactNode } from "react";
import { getNextJsApiURL, getStrapiURLClientSide } from "./api";
import { CMS_ROLE_ADMIN } from "./constants";
import { isLocaleDefaultLocale, translate } from "./localizationUtil";
import { createToast } from "./utilComponents";

/**
 * Returns Object with key: value pairs from array that can be saved by strapi.
 * @param {*} array
 * @param {*} positionOfKey
 * @param {*} positionOfValue
 */
export const arrayToKeyValuePairsObject = (
  array: Array<any>,
  keyPos: number,
  valuePos: number
): any => {
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[keyPos]]: item[valuePos],
    };
  }, {});
};

/** TODO: add type for listItem
 * takes a dynamiclist listItem as a property
 * and returns the type associated with the not null fields
 * (used in news)
 * @param {*} listItem
 * @returns listItemType
 */
export const getDynamicListItemType = (listItem: any): string => {
  let listItemType = "";
  if (listItem.richTextContent !== null || listItem.itemType === "richtext") {
    listItemType = "richtext";
  } else if (
    (listItem.linkUrl !== null && listItem.linkText !== null) ||
    listItem.itemType === "link"
  ) {
    listItemType = "link";
  } else if (
    (listItem.imgAlt !== null && listItem.imgCaption !== null) ||
    listItem.itemType === "image"
  ) {
    listItemType = "image";
  } else if (listItem.itemType === "gallery") {
    listItemType = "gallery";
  } else if (listItem.itemType === "multimedia") {
    listItemType = "multimedia";
  }

  return listItemType;
};

export const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * CopyToClipboard
 * Working: Opera, Safari, Chrome, Edge, Firefox
 * https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
 * @param {String} text
 * @returns
 */
export const copyToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text).then(
    function () {
      // Success-Case:
      createToast({
        type: "success",
        msg: cmsTranslate("copyToClipboardSuccess"),
      });
    },
    function () {
      // Error-Case:
      createToast({
        type: "error",
        msg: `${text} ${cmsTranslate("copyToClipboardError")}`,
      });
    }
  );
};

/**
 * Creates a deep immutable copy of the object using JSON serialization.
 * Note: This method is not suitable for circular object structures or objects containing functions.
 * @param {Object} obj - The object to copy.
 * @returns {Object} - The deep immutable copy of the object.
 */
export const deepImmutableCopy = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * Deeply merge two objects with nested objects and arrays. The second object
 * takes precedence over the first one, and conflicts are resolved by using
 * the values from the second object.
 *
 * @param {Object} target - The target object to merge into.
 * @param {Object} source - The source object whose values will be merged into the target.
 * @returns {Object} - A new object containing the merged values.
 *
 * @throws {TypeError} If either `target` or `source` is not an object or an array.
 *
 * @description
 * This function recursively merges the `source` object into the `target` object.
 * - If a key exists in both objects, the value from the `source` object takes precedence.
 * - If a value is `undefined` or `null` in the `source` object, it will overwrite the corresponding value in the `target` object.
 * - Circular references in either `target` or `source` may lead to unexpected behavior.
 *
 * @example
 * const target = {
 *   a: 1,
 *   b: {
 *     c: 2,
 *     d: [3, 4],
 *   },
 *   e: [5, 6],
 * };
 *
 * const source = {
 *   b: {
 *     c: 7,
 *     d: [8],
 *   },
 *   e: [9],
 *   f: 10,
 * };
 *
 * const mergedObject = deepMerge(target, source);
 * // output: { "a": 1, "b": { "c": 7, "d": [3, 4, 8] }, "e": [5, 6, 9], "f": 10 }
 * // The result will be a merged object with values from source taking precedence.
 */
export const deepMerge: any = (target: any, source: any) => {
  if (typeof target !== "object" || typeof source !== "object") {
    return source;
  }

  if (Array.isArray(target) && Array.isArray(source)) {
    return [...target, ...source];
  }

  const merged: any = { ...target };

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (typeof source[key] === "object" && source[key] !== null) {
        if (typeof merged[key] === "object" && merged[key] !== null) {
          merged[key] = deepMerge(merged[key], source[key]);
        } else {
          merged[key] = source[key];
        }
      } else {
        merged[key] = source[key];
      }
    }
  }
  return merged;
};

export const sleep = (ms: number): Promise<void> =>
  new Promise((r) => setTimeout(r, ms));

export const isSSR = (): boolean => typeof window === "undefined";

export const convertPropertyPath = (attributePath: string): string => {
  return attributePath.replaceAll("[", ".").replaceAll("]", "");
};

export const getRandomSlug = (): string => {
  return Math.random().toString(36).substring(2, 5);
};

export const getIdOrNewId = (element: any, index: number): string | number => {
  if (element.id) {
    return element.id;
  } else if (element.__new_id) {
    return element.__new_id;
  }
  return index;
};

export const getPageTitle = (page: any): string => {
  if (page.isSeoTitlePageTitle) {
    return getMetaTitleOrNull(page) ?? page.name;
  }
  return page.name;
};

/**
 * Takes Markdown or HTML as param.
 * Adds line-breaks before each p and h tag.
 * @param {String} text
 * @returns formatted String
 */
export const formatMarkdownOrHTMLToString = (text: string): string | null => {
  let onlyText: string | null = "";
  if (text !== undefined && text !== null && text !== "") {
    // Render HTML first, to not destroy Markdown-Elements.
    let validHTML = marked(text);

    // get all positions in string before a <p> or <h>
    let positionsOfPTags = [];
    for (let i = 0; i < validHTML.length; i++) {
      if (
        validHTML[i] === "<" &&
        (validHTML[i + 1] === "p" || validHTML[i + 1] === "h")
      ) {
        if (i !== 0) {
          positionsOfPTags.push(i);
        }
      }
    }

    // adds line-breaks before each <p> and <h>
    positionsOfPTags.forEach((position, index) => {
      if (index === 0) {
        validHTML = [
          validHTML.slice(0, position),
          " \n",
          validHTML.slice(position),
        ].join("");
      } else {
        validHTML = [
          validHTML.slice(0, position + index * 2),
          " \n",
          validHTML.slice(position + index * 2),
        ].join("");
      }
    });

    // Use HTML-Element to make use of HTML-Functionality
    const tempDIV = document.createElement("div");
    tempDIV.innerHTML = validHTML;
    onlyText = tempDIV.textContent;
  }
  return onlyText;
};

/**
 * This function returns the Bootstrap row classname for the given text-alignment
 * e.g. alignment: "left" -> returns "justify-content-start"
 *
 * @param {String} alignment "left", "center", "right"
 * @returns the Bootstrap classname corresponding the text-alignment, defaults to empty String
 */
export const getBootstrapAlignmentRowClass = (alignment: string): string => {
  switch (alignment) {
    case "left":
      return "justify-content-start";
    case "center":
      return "justify-content-center";
    case "right":
      return "justify-content-end";
    default:
      return "";
  }
};

/**
 * This function returns the Bootstrap classname for the given text-alignment
 * e.g. alignment: "left" -> returns "text-start"
 *
 * @param {String} alignment "left", "center", "right"
 * @returns the Bootstrap classname corresponding the text-alignment, defaults to empty String
 */
export const getBootstrapTextAlignmentClass = (alignment?: string): string => {
  switch (alignment) {
    case "left":
      return "text-start";
    case "center":
      return "text-center";
    case "right":
      return "text-end";
    default:
      return "";
  }
};

export const getPublicOrPrivateUrlFromManagedFile = (
  managedFile: any
): string => {
  if (!managedFile || !managedFile.file) {
    return "";
  }

  if (managedFile.isPrivate) {
    return getNextJsApiURL(managedFile.file.url);
  }
  return getStrapiURLClientSide(managedFile.file.url);
};

/**
 * @param {Object} numberObj the value you want to convert to rem - e.g. 15px or 15
 * @returns {Number} the rem-value or 0, if the given numberObj was 0, undefined or null
 */
export const getRemValue = (numberObj: any): number => {
  if (!numberObj || numberObj === undefined || numberObj === 0) {
    return 0;
  }
  if (!Number.isInteger(numberObj) && numberObj.endsWith("px")) {
    numberObj = numberObj.substring(0, numberObj.length - 2);
  }

  return (numberObj / 10) as number;
};

/**
 * checks a role and executes the given function after that role check.
 * If the rolecheck is successful the function is executed if not there is a toast message shown
 *
 * () => functionCall(a, b)
 *
 * @param {function} callFunction the function that should be called after successful role check
 * @param {Array string} roles the roles needed to run that function
 * @param {string} showNotification optional - defaults to true so the default permission denied notification will be displayed
 * @param {string} customNotificationText  optional - custom permission denied msg
 */
export const roleCheck = async (
  callFunction: Function,
  roles: Array<string>,
  showNotification?: boolean,
  customNotificationText?: string
): Promise<any> => {
  if (typeof showNotification === "undefined") {
    showNotification = true;
  }

  if (cmsUserHasRole(roles, showNotification, customNotificationText)) {
    return callFunction();
  }
  return null;
};

/**
 * checks if a CMS user has a specific role that is needed
 * Attention: this is only a frontend check!! Always check permissions/roles in backend
 *
 * @param {Array string} roles the rolenames that will be checked as an array example: [CMS_ROLE_AUTHOR, CMS_ROLE_EDITOR]
 * @param {boolean} showNotification optional - enabled/disables default permission denied
 *  toast notification if this function runs calls showPermissionDeniedNotification
 * @param {string} customNotificationText optional - custom permission denied notification text
 * @returns {boolean} true if the user does have the role / false if the user does not have the role
 */
export const cmsUserHasRole = (
  roles: Array<string>,
  showNotification?: boolean,
  customNotificationText: string = ""
): boolean => {
  const userRoles = reduxStore.getState().cmsUser.user.roles;
  if (
    userRoles !== null &&
    userRoles.length > 0 &&
    (userRoles.some((userRole) => userRole.name === CMS_ROLE_ADMIN) ||
      userRoles.some((userRole) => roles.includes(userRole.name)))
  ) {
    return true;
  } else {
    if (showNotification) {
      showPermissionDeniedNotification(customNotificationText);
    }

    if (process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT !== "prod") {
      global.log.info(`role needed: ${roles}`);
    }

    return false;
  }
};

/** TODO: only used in util
 * shows a simple permission denied toast msg text is optional
 *
 * @param {string} msg optional - for custom permission denied texts
 */
export const showPermissionDeniedNotification = (msg: string) => {
  const notifyMsg = msg ? msg : translate("cms:permissionDenied");
  createToast({ type: "warning", msg: notifyMsg });
};

export const getJustifyContentClassName = (alignment: string): string => {
  switch (alignment) {
    case "left":
      return "justify-content-start";
    case "middle":
      return "justify-content-center";
    case "right":
      return "justify-content-end";
    default:
      return "justify-content-start";
  }
};

export const getTextAlignmentClassName = (alignment: string): string => {
  switch (alignment) {
    case "left":
      return "text-left";
    case "middle":
      return "text-center";
    case "right":
      return "text-right";
    default:
      return "text-left";
  }
};

/**
 * combines pages and News to one list with identifier
 * TODO: add types for page object
 * @param {Array} pages
 * @param {Array} news
 *
 * @returns Array with pages and news
 */
export const combineAllPageTypesContents = (pages: Array<any>): Array<any> => {
  let pagesWithIdentifier = [];

  if (pages && pages.length > 0) {
    pagesWithIdentifier = pages.map((page) => {
      return {
        ...page,
        pageType: "page",
        groupByLabel: "pages",
      };
    });
  }

  return [...pagesWithIdentifier];
};

/**
 * Creates an ID that is used to jump to the element on the page.
 * The same pattern is used in the search.
 *  * @param {Obj} content strapi-component
 */
export const searchableID = (content: any): string => {
  if (content && content.__component && content.id) {
    return `${content.__component.replace("pb.", "")}-${content.id}`;
  } else {
    return "not-searchable";
  }
};

export const removeSpecialCharacters = (
  value: string,
  keepHyphen: boolean = false
): string => {
  if (keepHyphen) {
    return value.replace(/[^\w\s-]/gi, "");
  }
  return value.replace(/[^\w\s]/gi, "");
};

export const translateUmlaute = (value: string): string => {
  value = value.replace(/ä/g, "ae");
  value = value.replace(/ö/g, "oe");
  value = value.replace(/ü/g, "ue");
  value = value.replace(/ß/g, "ss");
  return value;
};

/**
 * Returns the youtube preview image
 * @param {String} url e.g. "https://www.youtube.com/watch?v=jNQXAC9IVRw"
 * @returns url or empty string if given url is invalid
 */
export const getYoutubePreviewImage = (url: string): string => {
  let sanitizedUrl = "";
  if (url) {
    const regEx =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    const match = url.match(regEx);
    // ignore invalid youtube url
    if (match && match[2].length == 11) {
      sanitizedUrl = "https://i3.ytimg.com/vi/" + match[2] + "/0.jpg";
    }
  }
  return sanitizedUrl;
};

export function getCssVariableValue(variableName: string): string {
  if (typeof document !== "undefined" && document.documentElement) {
    const styleDeclaration = getComputedStyle(document.documentElement);
    return styleDeclaration.getPropertyValue(variableName).trim();
  }
  return "#ffffff";
}

/*
 * takes hex value and transforms returns an array with the rgb values
 * @param {*} hex
 * @returns RGB values array
 */
export const hexToRgb = (hex: string | Array<number>): Array<number> => {
  if (typeof hex === "string") {
    const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (normal) return normal.slice(1).map((e) => parseInt(e, 16));

    const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    if (shorthand) return shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));

    return [0, 0, 0];
  }
  return [0, 0, 0];
};

/**
 * E.g.: "pb.frmbl" (form boolean) to "checkbox"
 * @param {String} formFieldName
 */
export const getFormfieldComponentName = (formFieldName: string): string => {
  // the component name is translated and text has a different translation so message is used
  if (formFieldName.includes("text")) {
    return "message";
  }
  return formFieldName.replace("pb.", "");
};

/**
 * E.g.: "contentelements.headline" to headline
 * @param {String} contentElementString
 */
export const getContentElementName = (contentElementString: string): string => {
  return contentElementString.replace("contentelements.", "");
};

/**
 * E.g.: "repeatablecontent.headline" to headline
 * @param {String} repeatableContentElementString
 */
export const getRepeatableContentElementName = (
  repeatableContentElementString: string
): string => {
  return repeatableContentElementString.replace("repeatablecontent.", "");
};

export const getFileUploadStatusCodeMessage = (status: number) => {
  switch (status) {
    case 413:
      return translate("cms:fileTooBig");
    case -1:
    default:
      return translate("cms:unexpectedError");
  }
};

/**
 * cleans the browser cache, mostly for the SW (Serviceworker)
 */
export const cleanCache = (): void => {
  const cacheName = "old_cache";
  (async () => {
    try {
      const keys = await caches.keys();
      return keys.map(async (cache) => {
        if (cache !== cacheName) {
          global.log.info("Service Worker: Removing old cache: " + cache);
          return await caches.delete(cache);
        }
      });
    } catch (error) {
      global.log.error("Could not clean cache. (Private Tab?)");
      console.error(error);
    }
  })();
};

/**
 * Use this in a content-element to set the background-color of the container
 * @param {Object} cfgBackgroundColor props.content.cfgBackgroundColor from CE
 * @param {Object} settings props.settings from CE
 * @returns
 */
export const contentElementBGColor = (
  cfgBackgroundColor: "primary" | "secondary" | "" | undefined | null,
  settings: any
): string => {
  if (cfgBackgroundColor === "primary") {
    return settings.backgroundColorPrimary;
  } else if (cfgBackgroundColor === "secondary") {
    return settings.backgroundColorSecondary;
  }
  return "unset";
};

/**
 * spaceInPercent
 * Note: If you are in need for a significant larger spaceX in WQHD and above: scalingfactoSpaceX2kPlus: 5+ it is.
 * @param {Number} spaceXpx spaceX: 100px
 * @param {Number} breakpoint breakpoint: 999px
 * @param {Number} factor scalingFactor: 0.75
 * @returns {String} with %!
 */
export const spaceInPercent = (
  spaceXpx: number,
  breakpoint: number,
  factor: number = 1
): string => {
  // global.log.debug(
  //   { spaceXpx, breakpoint, factor },
  //   `[spaceInPercent] ${Math.floor((spaceXpx / breakpoint) * 100 * factor)}%`
  // );
  return `${Math.floor((spaceXpx / breakpoint) * 100 * factor)}%`;
};

/**
 * getCssMaxWidthValue
 * Returns 100% or the px-Value.
 * @param {Number} cfgMaxWidthValue
 * @param {Boolean} cfgIgnoreMaxWidthValue
 * @returns {String} CSS Attribute Value (Value + Unit)
 */
export const getCssMaxWidthValue = (
  cfgMaxWidthValue: number | undefined,
  cfgIgnoreMaxWidthValue: boolean
): string => {
  // TBD: We could also transform very high cfgMaxWidthValues (2000+) to 100% here.
  // For now: These values are wanted/configuration errors.
  if (cfgIgnoreMaxWidthValue || !cfgMaxWidthValue) {
    return "100%";
  }
  return `${cfgMaxWidthValue}px`;
};

export const removeWrappingSingleQuotes = (text: string): string => {
  if (text.charAt(0) === "'" && text.charAt(text.length - 1) === "'") {
    return text.substring(1, text.length - 1);
  }
  return text;
};

export const handleResponseToastNotifications = (
  result: ResponseResult<any>,
  generalSuccess: string,
  generalError: string,
  namespace: string = "cms"
): void => {
  if (!result.success) {
    /** @type {Array<{ error: string }>} */
    const errorArray = result.error.response?.data?.message;
    if (errorArray instanceof Array && errorArray.length > 0) {
      for (const errorObj of errorArray) {
        createToast({
          type: "warning",
          msg: translate(errorObj.error),
        });
      }
    }
    createToast({
      type: "error",
      msg: translate(`${namespace}:${generalError}`),
    });
  } else {
    createToast({
      type: "success",
      msg: translate(`${namespace}:${generalSuccess}`),
    });
  }
};

/**
 * Checks if the meta title should be used as page title
 * Finds the meta tag with name="title" and returns its contents or null
 * @param {*} pageData page instance
 * @returns meta title or null
 */
export const getMetaTitleOrNull = (pageData: any): string | null => {
  if (pageData.isSeoTitlePageTitle) {
    const match = pageData.seoSettings.match(
      /<meta name="title" content="(.+)" \/>/
    );
    if (match && match[1]) {
      return match[1];
    }
  }
  return null;
};

/**
 * This function is mainly used for strapi enum fields
 * where you can choose from a dropdown (enum)
 * to select a number. In strapi you can not assign
 * only numbers to the dropdown enum values.
 *
 * currently this is used to select the bootstrap col
 * count.
 *
 * @param {String} numberString
 * @param {number} defaultValue optional the default value that gets returned (default is 3)
 * @returns
 */
export const getIntegersFromEnum = (
  numberString?: "one" | "two" | "three" | "four" | "five" | "six",
  defaultValue: number = 3
): number => {
  const enumMappings = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
  };

  if (numberString && enumMappings.hasOwnProperty(numberString)) {
    return enumMappings[numberString];
  } else {
    return defaultValue;
  }
};

/**
 * Reloads the page and always triggers getServerSideProps to re-fetch page data.
 * router.replace("/foo#bar") does not trigger getServerSideProps.
 * With this workaround it does.
 * @param {Object} router the useRouter from nextJS
 * @example
 * const router = useRouter();
 * await triggerGetServerSideProps(router);
 */
export const triggerGetServerSideProps = async (
  router: NextRouter
): Promise<void> => {
  if (router.asPath.includes("#")) {
    const firstReplaceFinished = await router.replace(window.location.pathname);
    if (firstReplaceFinished) {
      await router.replace(window.location.pathname);
    }
  } else {
    await router.replace(router.asPath);
  }
};

/**
 * Returns the localized alt/title text of an file
 * @param {string} type e.g. "title" or "altText"
 * @param {Object} file
 * @param {string} locale e.g. router.locale
 * @returns
 */
export const getFileAltTextOrTitle = (
  type: string,
  file: any,
  locale?: string
): string => {
  if (!file || !type || !locale) {
    return "";
  }

  if (isLocaleDefaultLocale(locale)) {
    return file[type] || "";
  }

  if (file.localization) {
    return file.localization[locale][type];
  }
  return "";
};

// globalConfig: Util
// Transforms the upperBreakpoints from globalConfig.responsive to lowerBreakpoints.
export const lowerBreakPointMobile = 0;
export const lowerBreakpointMobilePx = `${lowerBreakPointMobile}px`;

export const lowerBreakpointTablet =
  globalConfig?.responsive?.breakpoints?.mobile ?? 768;
export const lowerBreakpointTabletPx = `${lowerBreakpointTablet}px`;

export const lowerBreakpointDesktop =
  globalConfig?.responsive?.breakpoints?.tablet ?? 1366;
export const lowerBreakpointDesktopPx = `${lowerBreakpointDesktop}px`;

export const lowerBreakpointWqhd =
  globalConfig?.responsive?.breakpoints?.desktop ?? 2560;
export const lowerBreakpointWqhdPx = `${lowerBreakpointWqhd}px`;

export const lowerBreakpoint4k =
  globalConfig?.responsive?.breakpoints?.wqhd ?? 3840;
export const lowerBreakpoint4kPx = `${lowerBreakpoint4k}px`;

/**
 * Builds metatags for pages.
 * NOTE: Currently only works with name, property and content attributes on meta tags
 * build as a replacement for html-react-parser
 * @param {*} seoSettings props.page.seoSettings object
 * @returns <meta name="xx" content="xx" />
 *          <meta name="yy" content="yy" />
 */
export const buildMetaTags = (seoSettings: string): JSX.Element[] => {
  if (!seoSettings) {
    return [];
  }
  const regex =
    /<meta\s+((?:name|property))="([^"]+)"\s+content="([^"]+)"\s*\/>/g;
  let match;
  const metaTags: JSX.Element[] = [];

  while ((match = regex.exec(seoSettings)) !== null) {
    const myAttribute = match[1];
    const attributeName = match[2].toLowerCase();
    const content = match[3];
    const metaTagProps =
      myAttribute === "name"
        ? { name: attributeName }
        : { property: attributeName };
    const metaTagElement = React.createElement("meta", {
      ...metaTagProps,
      content,
      key: attributeName,
    });

    metaTags.push(metaTagElement);
  }
  return metaTags;
};

/**
 * Takes a string of HTML and turns it into an array of ReactNodes/JSX.Element's
 * @param htmlString
 * @returns
 */
export const buildTags = (htmlString: string): ReactNode[] => {
  if (!htmlString) {
    return [];
  }
  const regex = /<(\w+)(\s+[^>]*)?>([^<]*)<\/\1>|<(\w+)(\s+[^>]*)?\/?>/g;

  let match;
  const elements: ReactNode[] = [];

  while ((match = regex.exec(htmlString)) !== null) {
    const tagName = match[1] || match[4];
    const attributesString = match[2] || match[5] || "";
    const innerContent = match[3] || "";

    const attributesRegex = /(\w+)=["']([^"']*)["']/g;
    let attrMatch;
    const props: any = { key: elements.length };

    while ((attrMatch = attributesRegex.exec(attributesString)) !== null) {
      const attrName = attrMatch[1];
      const attrValue = attrMatch[2];
      props[attrName] = attrValue;
    }

    if (tagName === "script" && innerContent) {
      elements.push(
        React.createElement(tagName, {
          ...props,
          dangerouslySetInnerHTML: {
            __html: innerContent,
          },
        })
      );
    } else if (innerContent) {
      elements.push(React.createElement(tagName, props, innerContent));
    } else {
      elements.push(React.createElement(tagName, props));
    }
  }

  return elements;
};

/**
 * Generates a new unique scopedSelector for use in pbContent..components
 * @param scopedSelector scoped selector of the contentelement
 * @param suffix optional - add this parameter if a pbContent..component is used multiple times in the same contentelement (e.g. headline and subheadline)
 * @param nestedPosition optional - add this if the pbContent..component can be nested x times in the contentelement
 * @returns
 */
export const getPbContentScopedSelector = (
  scopedSelector: string,
  suffix: string = "",
  nestedPosition: number | string = ""
) => {
  return `${scopedSelector}${nestedPosition ? `-${nestedPosition}` : ""}${
    suffix ? `-${suffix}` : ""
  }`;
};

export const createHref = (url: string, anchor: string, params: string) => {
  const linkAnchor = anchor ? "#" + anchor.replaceAll("#", "") : "";
  const linkParams = params ? "?" + params.replaceAll("?", "") : "";
  return `${url}${linkAnchor}${linkParams}`;
};

/**
 * isStoreValueDefaultAllowed
 * If there's -1 in the storeValues of the ceSetting, the optional "default" is allowed.
 * @returns boolean
 */
export const isStoreValueDefaultAllowed = (
  storeSettingValues: Array<number>
) => {
  return storeSettingValues.indexOf(-1) !== -1 ? true : false;
};

/**
 * getEnforcedStoreValueId
 * If there's only one storeValue in the storeValues of the ceSetting, that value should be enforced.
 * This function check's if that's the case, compares the value of the contentElement with ceSetting-storeValue and
 * returns the correct value.
 *
 * 1. Checks if there's only one storeValue in storeValues.
 * 2. Compares value with element.value.
 * 3. If there's a difference: the single storeValue is returned.
 * @param cfgStrAttributeValue
 * @param storeSetting
 * @param checkType
 * @returns number | undefined
 */
export const getEnforcedStoreValueId = (
  cfgStrAttributeValue: StoreSetting | undefined,
  storeSetting: StoreSetting,
  checkType: boolean = false
): number | undefined => {
  // optional:
  if (checkType && cfgStrAttributeValue?.storeType !== storeSetting.storeType) {
    global.log.info(
      `[getEnforcedStoreValueId] Mismatch of storeType between content.cfgStr and cesstr.ceSetting.`
    );
    // Return cfgStrAttributeValue.values because storeSetting can include multiple values.
    return sanitizeOptionalDefault(cfgStrAttributeValue?.values[0]);
  }

  // no enforcedValue
  if (storeSetting && storeSetting.values.length !== 1) {
    return sanitizeOptionalDefault(cfgStrAttributeValue?.values[0]);
  }

  // enforcedValue matches with value or should be enforced
  return sanitizeOptionalDefault(storeSetting?.values[0]);
};

/**
 * Replaces the optional default value -1 with undefined.
 * @param value
 * @returns
 */
export const sanitizeOptionalDefault = (value: number | undefined) => {
  if (value === -1) {
    return undefined;
  }
  return value;
};

export const navigationLinkStyleByLevel = (level: number) => {
  switch (level) {
    case 1:
      return storeLinkClassName(globalConfig?.navigation.linkTopLevel);
    case 2:
      return storeLinkClassName(globalConfig?.navigation.linkLevel2);
    case 3:
      return storeLinkClassName(globalConfig?.navigation.linkLevel3);
    default:
      return undefined;
  }
};

export const imageFormat = (
  file: StrapiUploadFile,
  format: string | undefined
) => {
  // Skips formats if: file is a gif, no format exists, no matching format exists.
  if (isGIF(file) || !format || !file.formats?.[format]) {
    return { ...file } as StrapiUploadFileFormat;
  }
  return file.formats[format];
};

/**
 * Returns true if URL ends with .gif.
 * @param file
 * @returns
 */
export const isGIF = (file: StrapiUploadFile) => {
  return file && file.url && file.url.endsWith(".gif");
};

/**
 * Validates if the given string is a valid email address.
 * @param {string} email - The email address to be validated.
 * @returns {boolean} - Returns `true` if the email is valid, otherwise `false`.
 */
export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
